import { firebaseCollections, firebaseFirestore, firebaseStorageDirectories, uploadImageToStorage } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import dayjs from 'dayjs'
import { doc, onSnapshot } from 'firebase/firestore'
import { ChatContent } from 'modules/chat/components/chat-content'
import { useTextForm } from 'modules/chat/hooks/use-text-form'
import { ChatType } from 'modules/chat/types/chat.type'
import { FileFormSchemaType } from 'modules/chat/types/file-form.schema'
import { MessageRole, MessageType, MessageVariant } from 'modules/chat/types/message.type'
import { TextFormSchemaType } from 'modules/chat/types/text-form.schema'
import { useFileForm } from 'modules/chat/types/use-file-form'
import { getChatFileName } from 'modules/chat/utils/chat-file-name'
import { useCallback, useEffect } from 'react'
import { useClientChatStore } from '../store/client-chat.store'

export const useClientChat = ({
    id,
    role,
    lastMessageRef,
    instanceId,
}: {
    id?: string
    instanceId: string
    role: MessageRole
    lastMessageRef?: React.RefObject<React.ComponentRef<typeof ChatContent>>
}) => {
    const textForm = useTextForm()
    const fileForm = useFileForm()
    const fetchChatById = useClientChatStore(state => state.fetchChatById)

    const chat = useClientChatStore(state =>
        state.chats.find(chat => chat.id === id && chat.instanceId === instanceId) ||
        state.archivedChats.find(chat => chat.id === id && chat.instanceId === instanceId) ||
        state.bannedChats.find(chat => chat.id === id && chat.instanceId === instanceId),
    )
    const isIdLoading = useClientChatStore(state => state.isIdLoading)
    const setChatById = useClientChatStore(state => state.setChatById)
    const sendMessage = useClientChatStore(state => state.sendMessage)
    const checkBan = useClientChatStore(state => state.checkBan)

    useEffect(() => {
        if (!id) {
            return
        }
        fetchChatById(id)
    }, [fetchChatById, id])

    useEffect(() => {
        if (chat && id) {
            window.parent.postMessage({ chat: chat, chatId: id }, '*')
        } else {
            window.parent.postMessage({ chat: null, chatId: null }, '*')
        }
    }, [chat, id])

    const isClientUserBanned = useCallback(async () => {
        if (!chat) {
            throw new Error('Chat is not defined')
        }
        const isBanned = await checkBan(chat)
        if (role === MessageRole.CLIENT) {
            return isBanned
        }
        return false
    }, [chat, checkBan, role])

    const onSendMessage = useCallback(
        async (values: TextFormSchemaType) => {
            try {
                if (!values || !chat) {
                    throw new Error('Chat or values are not defined')
                }
                // check if user is banned if role is client
                const isBanned = await isClientUserBanned()
                if (isBanned) {
                    toastConfig.chat.sendMessage.notAllowed()
                    return
                }

                // reset form and create message
                textForm.reset()
                // do not send empty messages
                if (values.text.trim().length === 0) {
                    return
                }
                const newMessage: MessageType = {
                    createdAt: dayjs().toISOString(),
                    //
                    type: MessageVariant.TEXT,
                    content: values.text.trim(),
                    senderRole: role,
                }
                sendMessage(chat, newMessage)
                lastMessageRef?.current?.scrollIntoView?.(false)
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
        },
        [chat, textForm, isClientUserBanned, lastMessageRef, role, sendMessage],
    )

    const onSendFile = useCallback(
        async (values: FileFormSchemaType) => {
            try {
                if (!values || !chat) {
                    throw new Error('Chat or values are not defined')
                }
                // check if user is banned if role is client
                const isBanned = await isClientUserBanned()
                if (isBanned) {
                    toastConfig.chat.sendMessage.notAllowed()
                    return
                }
                // save file to firebase storage
                const imageUrl = await uploadImageToStorage(
                    firebaseStorageDirectories.chatMedia,
                    values.file,
                    getChatFileName(chat),
                )
                // reset form and create message
                fileForm.reset()
                const newMessage: MessageType = {
                    createdAt: dayjs().toISOString(),
                    //
                    type: MessageVariant.IMAGE,
                    content: imageUrl,
                    senderRole: role,
                }
                sendMessage(chat, newMessage)
                lastMessageRef?.current?.scrollIntoView?.(false)
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
        },
        [chat, isClientUserBanned, lastMessageRef, role, sendMessage, fileForm],
    )

    useEffect(() => {
        if (!id) {
            return
        }
        const unsub = onSnapshot(doc(firebaseFirestore, firebaseCollections.chats, id), ref => {
            const data = ref.data()
            const chat = {
                id: ref.id,
                ...data,
            } as ChatType
            setChatById(chat)
        })
        return () => {
            unsub()
        }
    }, [id, setChatById])

    return {
        textForm,
        fileForm,
        chat,
        isIdLoading,
        onSendMessage,
        onSendFile,
    } as const
}
