import { LoadingButton } from 'components/loading-button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { LoginSchemaType } from 'modules/user/types/schemas/login.schema'
import { memo } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'

interface LoginEmailFormProps {
    form: UseFormReturn<LoginSchemaType>
    onSubmit: SubmitHandler<LoginSchemaType>
    loading?: boolean
}
export const LoginEmailForm: React.FC<LoginEmailFormProps> = memo(({ form, onSubmit, loading = false }) => {
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                <FormField
                    control={form.control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder='someemail@email.com' {...field} required/>
                            </FormControl>
                            <FormDescription className='text-xs'>
                                After entering your email, you'll have to confirm it by clicking on the link in the letter
                            </FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <LoadingButton type='submit' loading={loading}>
                    Submit email
                </LoadingButton>
            </form>
        </Form>
    )
})
LoginEmailForm.displayName = LoginEmailForm.name
