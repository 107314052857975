import { Loader2 } from 'lucide-react'
import { memo } from 'react'
import { cn } from 'utils/cn'

interface LoadingIconProps extends React.ComponentProps<typeof Loader2> {
    loading?: boolean
}
export const LoadingIcon: React.FC<LoadingIconProps> = memo(({ loading = false, className, ...props }) => {
    if (!loading) return null

    return <Loader2 {...props} className={cn('repeat-infinite h-5 animate-spin opacity-70', className)}/>
})
LoadingIcon.displayName = LoadingIcon.name
