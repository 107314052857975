/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCurrentISODate } from 'config/dayjs.config'
import { dbBannedUsers, dbChats } from 'config/firebase.config'
import { where } from 'firebase/firestore'
import _ from 'lodash'
import { ChatType } from 'modules/chat/types/chat.type'
import { BannedUserFieldsEnum } from 'modules/user/types/banned-user.type'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { MessageRole, MessageType } from '../types/message.type'

interface UseClientChatStore {
    chats: ChatType[]
    archivedChats: ChatType[]
    bannedChats: ChatType[]
    clientChatId: ChatType['id']
    isAllLoading: boolean
    isIdLoading: boolean
    //
    setClientChatId: (id: ChatType['id']) => void
    updateChat: (chat: ChatType) => Promise<ChatType>
    setAllChats: (chats: ChatType[]) => void
    setChatById: (chat: ChatType) => void
    sendMessage: (
        chat: Pick<ChatType, 'id' | 'messages' | 'isUnread' | 'isAdminUnread'>,
        newMessage: MessageType,
    ) => Promise<void>
    //
    fetchChats: (id: string) => Promise<void>
    fetchChatById: (id: string) => Promise<ChatType | undefined>
    createChat: () => Promise<ChatType>
    //
    checkBan: (chat: ChatType) => Promise<boolean>
}

export const useClientChatStore = create<
    UseClientChatStore,
    [['zustand/devtools', UseClientChatStore], ['zustand/persist', UseClientChatStore], ['zustand/immer', UseClientChatStore]]
>(
    devtools(
        persist(
            immer((set, get) => ({
                chats: [] as ChatType[],
                archivedChats: [] as ChatType[],
                bannedChats: [] as ChatType[],
                clientChatId: '',
                isAllLoading: false as boolean,
                isIdLoading: false as boolean,

                //

                setClientChatId: id => {
                    set(state => {
                        state.clientChatId = id
                    })
                },

                //

                fetchChats: async id => {
                    set(state => {
                        state.isAllLoading = true
                    })
                    try {
                        const setAllChats = get().setAllChats
                        const allChats = await dbChats.query(where('instanceId', '==', id))
                        setAllChats(allChats)
                    } catch (error) {
                        console.error(error)
                    } finally {
                        set(state => {
                            state.isAllLoading = false
                        })
                    }
                },

                //

                fetchChatById: async id => {
                    set(state => {
                        state.isIdLoading = true
                    })
                    try {
                        const fetchedChat = await dbChats.getById(id)
                        if (!fetchedChat) {
                            throw new Error('Chat not found')
                        }
                        set(state => {
                            state.chats = state.chats.map(stateChat => {
                                if (stateChat.id !== id) {
                                    return stateChat
                                }
                                return fetchedChat
                            })
                        })
                        return fetchedChat
                    } catch (error) {
                        console.error(error)
                    } finally {
                        set(state => {
                            state.isIdLoading = false
                        })
                    }
                },

                //

                createChat: async () => {
                    const newChat = await dbChats.create({
                        createdAt: getCurrentISODate(),
                        //
                        isUnread: false,
                        isAdminUnread: false,
                        isArchived: false,
                        isBanned: false,
                        //
                        banInfo: null,
                        location: {
                            lat: 0,
                            lng: 0,
                            city: '',
                            country: '',
                        },
                        ip: '',
                        username: '',
                        messages: [],
                        instanceId: '',
                    })
                    set(state => {
                        state.chats = [...state.chats, newChat]
                    })
                    return newChat
                },

                updateChat: async chat => {
                    const setChatById = get().setChatById
                    setChatById(chat)
                    await dbChats.update(chat)
                    return chat
                },

                sendMessage: async (chat, newMessage) => {
                    await dbChats.update({
                        id: chat.id,
                        messages: [...chat.messages, newMessage],
                        isUnread: newMessage.senderRole !== MessageRole.ADMIN,
                        isAdminUnread: newMessage.senderRole === MessageRole.ADMIN,
                    })
                },

                setAllChats: (chats: any) => {
                    const chatsWithoutDuplicates = _.uniqBy(
                        [...get().chats, ...get().archivedChats, ...get().bannedChats, ...chats],
                        data => data.id,
                    )
                    set(state => {
                        state.chats = chatsWithoutDuplicates.filter(chat => !chat.isArchived && !chat.isBanned)
                        state.archivedChats = chatsWithoutDuplicates.filter(chat => chat.isArchived)
                        state.bannedChats = chatsWithoutDuplicates.filter(chat => chat.isBanned && !chat.isArchived)
                    })
                },
                setChatById: chat => {
                    set(state => {
                        state.chats = state.chats.map(stateChat => {
                            if (stateChat.id !== chat.id) {
                                return stateChat
                            }
                            return chat
                        })
                        state.archivedChats = state.archivedChats.map(stateChat => {
                            if (stateChat.id !== chat.id) {
                                return stateChat
                            }
                            return chat
                        })
                        state.bannedChats = state.bannedChats.map(stateChat => {
                            if (stateChat.id !== chat.id) {
                                return stateChat
                            }
                            return chat
                        })
                    })
                },

                checkBan: async chat => {
                    const updateChat = get().updateChat
                    if (chat?.isBanned) {
                        return chat.isBanned
                    }
                    const bannedUsers = await dbBannedUsers.query(where(BannedUserFieldsEnum.IP, '==', chat?.ip))
                    await updateChat({
                        ...chat,
                        isBanned: bannedUsers.length > 0,
                        banInfo: bannedUsers[0]
                            ? {
                                bannedAt: bannedUsers[0]?.bannedAt,
                                bannedBy: bannedUsers[0]?.bannedBy,
                                reason: bannedUsers[0]?.reason,
                            }
                            : null,
                    })
                    return bannedUsers.length > 0
                },
            })),
            {
                name: 'chat',
            },
        ),
    ),
)
