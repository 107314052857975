import { toast } from 'components/ui/use-toast'
import { urlConfig } from 'config/url.config'
import { useEffect, useState } from 'react'

export interface UserProfile {
    uid: string
    id: string
    name: string
    email: string
    emailVerified: boolean
    image: string
    lastSignIn: string
    role: string
}

export const useGetUserProfile = (id: string) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<UserProfile | null>(null)

    const fetchData = async (id: string) => {
        try {
            const res = await fetch(`${urlConfig.API_URL}${urlConfig.api.profile}/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!res.ok) {
                throw new Error('Failed to fetch users')
            }

            const responseData = (await res.json()) as { message: string; data: UserProfile }

            if (!responseData.data) {
                throw new Error('Failed to fetch user profile')
            }

            setData(responseData.data)
        } catch (error) {
            toast({
                title: 'Failed to fetch user profile',
                description: 'An error occurred while fetching user profile. Please try again later.',
                variant: 'destructive',
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(id)
    }, [id])

    const refetchData = () => {
        setLoading(true)
        fetchData(id)
    }

    return { loading, data, refetchData }
}
