import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toaster'
import { urlConfig } from 'config/url.config'
import { ChatLayout } from 'modules/chat/layouts/chat.layout'
import { ClientPage } from 'modules/client/client-page'
import Instance from 'modules/instances/pages/instance'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { ThemeUpdater } from 'modules/theme/components/theme-updater'
import { RequireAuth } from 'modules/user/components/hoc/require-auth'
import { ThrowAuth } from 'modules/user/components/hoc/throw-auth'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import Admin from 'pages/admin'
import { ChatPage } from 'pages/chat-page'
import { ErrorPage } from 'pages/error-page'
import { IndexPage } from 'pages/index-page'
import Login from 'pages/login'
import { LoginPage } from 'pages/login-page'
import { MainPage } from 'pages/main-page'
import { Navbar } from 'pages/Navbar'
import { NotFoundPage } from 'pages/not-found-page'
import Profile from 'pages/profile'
import Register from 'pages/register'
import ResetPassword from 'pages/reset-password'
import Statistics from 'pages/statistics'
import { memo, useEffect, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { NODE_ENV_DEV, NODE_ENV_PROD } from 'utils/NODE_ENV'

export const App: React.FC = memo(() => {
    const location = useLocation()
    const instances = useInstanceStore(state => state.instances)
    const setInstanceId = useInstanceStore(state => state.setInstanceId)
    const instanceId = useInstanceStore(state => state.instanceId)
    const path = useLocation().pathname
    const navigate = useNavigate()
    const root = useMemo(() => document.getElementById('root'), [])

    useEffect(() => {
        if (instances.length > 0 && instanceId && path === '/') {
            navigate(urlConfig.pages.instance.replace(':id', instances[0]?.id || ''), { replace: true })
            setInstanceId(instances[0]?.id || '')
        }
    }, [instances, instanceId, navigate, setInstanceId, path])
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Navbar/>
            <Routes>
                {/* auth only */}
                <Route
                    path={urlConfig.pages.main}
                    element={
                        <RequireAuth callbackPath={urlConfig.pages.login}>
                            <ChatLayout/>
                        </RequireAuth>
                    }
                >
                    <Route index element={<MainPage/>}/>
                    <Route path={urlConfig.pages.chatId} element={<ChatPage/>}/>
                    <Route path={urlConfig.pages.instance} element={<Instance/>}/>
                </Route>

                <Route
                    path={urlConfig.pages.profile}
                    element={
                        <RequireAuth>
                            <Profile/>
                        </RequireAuth>
                    }
                />
                <Route
                    path={urlConfig.pages.admin}
                    element={
                        <RequireAuth allowedRoles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
                            <Admin/>
                        </RequireAuth>
                    }
                />
                <Route
                    path={urlConfig.pages.statistics}
                    element={
                        <RequireAuth allowedRoles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
                            <Statistics/>
                        </RequireAuth>
                    }
                />
                {/* no auth */}
                <Route path={urlConfig.pages.client} element={<ClientPage/>}/>
                <Route
                    path={urlConfig.pages['all-login']}
                    element={
                        <ThrowAuth callbackPath={urlConfig.pages.main}>
                            <LoginPage/>
                        </ThrowAuth>
                    }
                />
                <Route
                    path={urlConfig.pages.login}
                    element={
                        <ThrowAuth callbackPath={urlConfig.pages.main}>
                            <Login/>
                        </ThrowAuth>
                    }
                />
                <Route
                    path={urlConfig.pages.register}
                    element={
                        <ThrowAuth callbackPath={urlConfig.pages.main}>
                            <Register/>
                        </ThrowAuth>
                    }
                />
                <Route
                    path={urlConfig.pages.reset}
                    element={
                        <ThrowAuth callbackPath={urlConfig.pages.main}>
                            <ResetPassword/>
                        </ThrowAuth>
                    }
                />
                <Route index element={<IndexPage/>}/>
                <Route path='*' element={<NotFoundPage/>}/>
            </Routes>
            {/*  */}
            <Toaster/>
            <ThemeUpdater/>
            <TailwindIndicator show={NODE_ENV_DEV && !NODE_ENV_PROD && location.pathname !== '/client'}/>
        </ErrorBoundary>
    )
})
App.displayName = 'App'
