import { pageTransitionClassName } from 'config/constants'
import { ChatContent } from 'modules/chat/components/chat-content'
import { ChatFooter } from 'modules/chat/components/chat-footer'
import { ChatHeader } from 'modules/chat/components/chat-header'
import { useClientChat } from 'modules/chat/hooks/use-client-chat'
import { useHandleImagePaste } from 'modules/chat/hooks/use-handle-image-paste'
import { useClientChatStore } from 'modules/chat/store/client-chat.store'
import { MessageRole } from 'modules/chat/types/message.type'
import { hexToHSL } from 'modules/chat/utils/hex-to-hsl'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { GetUsernameModal } from 'modules/user/components/get-username-modal'
import { GetUsernameFormSchemaType } from 'modules/user/types/schemas/get-username-form.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { cn } from 'utils/cn'
import { clientTextColor } from 'utils/functions'

interface ChatColors {
    contentBg: string
    headerCardBg: string
    footerCardBg: string
    clientMesBg: string
}

interface ClientPageProps {}
export const ClientPage: React.FC<ClientPageProps> = memo(() => {
    const [urlParams, setUrlParams] = useSearchParams()

    const root = document.querySelector<HTMLElement>(':root')

    const [chatColors, setChatColors] = useState<ChatColors>({
        contentBg: '#ffffff',
        headerCardBg: '#ffffff',
        footerCardBg: '#ffffff',
        clientMesBg: '#308cff',
    })
    const [textColor, setTextColor] = useState<string>('')
    const [textMessageColor, setTextMessageColor] = useState<string>('')
    const [textHeaderColor, setTextHeaderColor] = useState<string>('')
    const [textFooterColor, setTextFooterColor] = useState<string>('')

    const clientChatId = useClientChatStore(state => state.clientChatId)
    const updateChat = useClientChatStore(state => state.updateChat)
    const createChat = useClientChatStore(state => state.createChat)
    const setClientChatId = useClientChatStore(state => state.setClientChatId)
    const fetchChatById = useClientChatStore(state => state.fetchChatById)

    const fetchInstanceById = useInstanceStore(state => state.fetchInstanceById)
    const isInstanceIdLoading = useInstanceStore(state => state.isIdLoading)

    const inputRef = useRef<React.ComponentRef<typeof ChatFooter>>(null)
    const lastMessageRef = useRef<React.ComponentRef<typeof ChatContent>>(null)

    const { chat, textForm, fileForm, isIdLoading, onSendMessage, onSendFile } = useClientChat({
        id: clientChatId,
        role: MessageRole.CLIENT,
        lastMessageRef,
        instanceId: urlParams.get('q') ?? '',
    })

    const isGetUsernameModalOpen = useMemo(() => (chat?.username !== undefined && chat?.username.length === 0) || !chat, [chat])

    const onUsernameSubmit = useCallback(
        async (values: GetUsernameFormSchemaType) => {
            const ip = await getUserIp()
            const userLocation = await getUserLocation(ip)

            const newChat = await createChat()
            setClientChatId(newChat.id)

            await updateChat({
                ...newChat,
                location: {
                    lat: userLocation.lat,
                    lng: userLocation.lng,
                    city: userLocation.city,
                    country: userLocation.country,
                },
                ip,
                username: values.username,
                instanceId: urlParams.get('q') ?? '',
            })
            fetchChatById(newChat.id)
        },
        [createChat, fetchChatById, setClientChatId, updateChat, urlParams],
    )

    useEffect(() => {
        inputRef.current?.focus()
    }, [chat])

    useEffect(() => {
        if (urlParams.get('q')) {
            const fetchInstance = async () => {
                const fetchedInstance = await fetchInstanceById(urlParams.get('q') as string)
                const fetchedColors = fetchedInstance?.colorSettings && JSON.parse(fetchedInstance?.colorSettings)
                const contentBg = fetchedColors?.contentBg ? fetchedColors?.contentBg : '#ffffff'
                const headerCardBg = fetchedColors?.headerCardBg ? fetchedColors?.headerCardBg : '#ffffff'
                const footerCardBg = fetchedColors?.footerCardBg ? fetchedColors?.footerCardBg : '#ffffff'
                const clientMesBg = fetchedColors?.clientMesBg ? fetchedColors?.clientMesBg : '#308cff'

                setChatColors({
                    contentBg: contentBg,
                    headerCardBg: headerCardBg,
                    footerCardBg: footerCardBg,
                    clientMesBg: clientMesBg,
                })

                setTextHeaderColor(clientTextColor(headerCardBg))
                setTextColor(clientTextColor(contentBg))
                setTextMessageColor(clientTextColor(clientMesBg))
                setTextFooterColor(clientTextColor(footerCardBg))

                if (root) {
                    root.style.setProperty('--chat-header', hexToHSL(headerCardBg))
                    root.style.setProperty('--chat-footer', hexToHSL(footerCardBg))
                    root.style.setProperty('--chat-content', hexToHSL(contentBg))
                    root.style.setProperty('--message', hexToHSL(clientMesBg))
                }
            }
            fetchInstance()
        }
    }, [fetchInstanceById, root, urlParams])

    useHandleImagePaste({
        form: fileForm,
    })

    return (
        <>
            <div className={cn('relative flex h-full w-full flex-col', pageTransitionClassName)}>
                <GetUsernameModal textColor={textColor} initialOpen={isGetUsernameModalOpen} onSubmit={onUsernameSubmit}/>
                <ChatHeader
                    textColor={textHeaderColor}
                    chat={chat}
                    isClientPage={true}
                    isLoading={isIdLoading || isInstanceIdLoading}
                    className='pl-2'
                />
                <ChatContent
                    ref={lastMessageRef}
                    chat={chat}
                    role={MessageRole.CLIENT}
                    isLoading={isIdLoading || isInstanceIdLoading}
                    className=''
                    textMessageColor={textMessageColor}
                />
                <ChatFooter
                    ref={inputRef}
                    textForm={textForm}
                    onTextSubmit={onSendMessage}
                    fileForm={fileForm}
                    onFileFormSubmit={onSendFile}
                    isLoading={isIdLoading || isInstanceIdLoading}
                    className='pl-2 w-full p-0'
                    textColor={textColor}
                    textFooterColor={textFooterColor}
                />
            </div>
        </>
    )
})
ClientPage.displayName = ClientPage.name
