import { PageTitle } from 'components/page-title'
import { pageTransitionClassName } from 'config/constants'
import { urlConfig } from 'config/url.config'
import { ChatContent } from 'modules/chat/components/chat-content'
import { ChatFooter } from 'modules/chat/components/chat-footer'
import { ChatHeader } from 'modules/chat/components/chat-header'
import { ChatSidebar } from 'modules/chat/components/chat-sidebar'
import { useChat } from 'modules/chat/hooks/use-chat'
import { useHandleImagePaste } from 'modules/chat/hooks/use-handle-image-paste'
import { useRemoveNotificationBadge } from 'modules/chat/hooks/use-remove-notification-badge'
import { MessageRole } from 'modules/chat/types/message.type'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { cn } from 'utils/cn'

interface ChatPageProps {}
export const ChatPage: React.FC<ChatPageProps> = memo(() => {
    const { id } = useParams()

    const inputRef = useRef<React.ComponentRef<typeof ChatFooter>>(null)
    const lastMessageRef = useRef<React.ComponentRef<typeof ChatContent>>(null)

    const { chat, textForm, fileForm, isIdLoading, onSendMessage, onSendFile } = useChat({
        id,
        role: MessageRole.ADMIN,
        lastMessageRef,
    })

    const title = useMemo(() => {
        if (!chat) {
            return ''
        }
        if (!chat.username) {
            return 'Chat'
        }
        if (chat.isArchived) {
            return `${chat.username} - archived`
        }
        if (chat.isBanned) {
            return `${chat.username} - banned`
        }
        return chat.username
    }, [chat])

    useRemoveNotificationBadge(chat)

    // clear input on chat change
    useEffect(() => {
        textForm.reset()
    }, [chat, textForm])

    // focus input on chat change
    useEffect(() => {
        if (chat?.isArchived) {
            return
        }
        lastMessageRef.current?.focus()
        inputRef.current?.focus()
    }, [chat])

    // handle image paste
    useHandleImagePaste({
        form: fileForm,
    })

    // redirect to main page if chat not found
    if (!isIdLoading && !chat) {
        return <Navigate to={urlConfig.pages.main}/>
    }

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <div className={cn('relative flex h-[80%] w-full overflow-hidden', pageTransitionClassName)}>
                <div className='flex h-[90vh] mb-14 w-full flex-[3] flex-col flex-wrap'>
                    <ChatHeader chat={chat} isLoading={isIdLoading}/>
                    <ChatContent ref={lastMessageRef} chat={chat} role={MessageRole.ADMIN} isLoading={isIdLoading}/>
                    <ChatFooter
                        ref={inputRef}
                        textForm={textForm}
                        onTextSubmit={onSendMessage}
                        fileForm={fileForm}
                        onFileFormSubmit={onSendFile}
                        className='w-[75%] mb-14'
                        isLoading={isIdLoading}
                        archived={chat?.isArchived}
                    />
                </div>
                <ChatSidebar chat={chat}/>
            </div>
        </>
    )
})
ChatPage.displayName = ChatPage.name
