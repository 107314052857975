import { DataTableColumnHeader } from 'components/ui/data-table-column-header'

export const siteColumns = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title='Instance ID'/>,
        cell: ({ row }) => <span>{row.getValue('id')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title='Name'/>,
        cell: ({ row }) => <span>{row.getValue('name')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'origin',
        header: ({ column }) => <DataTableColumnHeader column={column} title='Origin'/>,
        cell: ({ row }) => <span>{row.getValue('origin')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
]
