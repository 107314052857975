import { CardDescription, CardTitle } from 'components/ui/card'
import { memo } from 'react'

interface SidebarButtonTextProps extends React.ComponentProps<'div'> {
    title: string
    content: string
    time: string
}
export const SidebarButtonText: React.FC<SidebarButtonTextProps> = memo(({ title, content, time, ...props }) => {
    return (
        <div {...props}>
            <div className='flex w-full items-start justify-between'>
                <CardTitle className='w-full max-w-[115px] overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium'>
                    {title}
                </CardTitle>
                <CardDescription className='text-[0.7rem] font-normal'>{time}</CardDescription>
            </div>
            <CardDescription className='w-full max-w-[170px] overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-normal'>
                {content?.replace('\n', '')}
            </CardDescription>
        </div>
    )
})
SidebarButtonText.displayName = SidebarButtonText.name
