import { Badge } from 'components/ui/badge'
import { getRelativeTime } from 'config/dayjs.config'
import dayjs from 'dayjs'
import { MessageType } from 'modules/chat/types/message.type'
import { memo, useMemo } from 'react'

interface ChatDateSeparatorProps {
    currentMessage: MessageType
    prevMessage: MessageType | undefined
    isLoading?: boolean
}
export const ChatDateSeparator: React.FC<ChatDateSeparatorProps> = memo(({ currentMessage, prevMessage, isLoading = false }) => {
    const isDifferentDay = useMemo(
        () => !dayjs(currentMessage?.createdAt)?.isSame(prevMessage?.createdAt, 'day'),
        [currentMessage?.createdAt, prevMessage?.createdAt],
    )
    const isFirstMessage = useMemo(() => !prevMessage?.createdAt, [prevMessage?.createdAt])

    if (isLoading || !(isDifferentDay || isFirstMessage)) {
        return null
    }

    return (
        <Badge
            variant='secondary'
            className='text-muted-foreground mx-auto my-2 flex w-fit py-1 text-center text-xs selection:bg-slate-600 selection:text-gray-300'
        >
            {getRelativeTime(currentMessage?.createdAt, {
                now: false,
                minutes: false,
                hours: false,
                days: true,
            })}
        </Badge>
    )
})
ChatDateSeparator.displayName = ChatDateSeparator.name
