import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Separator } from 'components/ui/separator'
import { toastConfig } from 'config/toast.config'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { InstanceType } from 'modules/instances/types/instance.type'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

const iconSchema = z.object({
    iconRadius: z.string(),
    iconBgColorStyle: z.string(),
    iconBg: z.string(),
    iconBgSecond: z.string().optional(),
    iconSize: z.string(),
    iconPosition: z.string(),
    iconPositionPadding: z.string(),
    iconBottomPadding: z.string(),
})

interface CustomizeChatIconProps {
    instance: InstanceType
    refetchInstance: () => void
}

export const CustomizeChatIcon: React.FC<CustomizeChatIconProps> = memo(({ instance, refetchInstance, ...props }) => {
    const navigate = useNavigate()

    const updateInstance = useInstanceStore(state => state.updateInstance)
    const fetchInstanceById = useInstanceStore(state => state.fetchInstanceById)
    const isIdLoading = useInstanceStore(state => state.isIdLoading)

    const iconForm = useForm<z.infer<typeof iconSchema>>({
        resolver: zodResolver(iconSchema),
        defaultValues: {
            iconRadius: 'square',
            iconBgColorStyle: 'gradient',
            iconBg: '#FFFFFF',
            iconBgSecond: '#1501c6',
            iconSize: '65',
            iconPosition: 'right',
            iconPositionPadding: '16',
            iconBottomPadding: '16',
        },
    })

    async function onSubmit(values: z.infer<typeof iconSchema>) {
        try {
            await updateInstance({
                ...(instance as InstanceType),
                iconSettings: JSON.stringify(values),
            })
            refetchInstance?.()
            toastConfig.instance.updateColors.success()
        } catch (error) {
            toastConfig.instance.updateColors.error()
        }
    }

    useEffect(() => {
        if (instance) {
            const fetchInstance = async () => {
                const fetchedIconSettings = instance?.iconSettings && JSON.parse(instance?.iconSettings)
                if (fetchedIconSettings) {
                    iconForm.setValue('iconBgColorStyle', fetchedIconSettings.iconBgColorStyle)
                    iconForm.setValue('iconBg', fetchedIconSettings.iconBg)
                    iconForm.setValue('iconBgSecond', fetchedIconSettings.iconBgSecond)
                    iconForm.setValue('iconSize', fetchedIconSettings.iconSize)
                    iconForm.setValue('iconPosition', fetchedIconSettings.iconPosition)
                    iconForm.setValue('iconRadius', fetchedIconSettings.iconRadius)
                    iconForm.setValue('iconPositionPadding', fetchedIconSettings.iconPositionPadding)
                    iconForm.setValue('iconBottomPadding', fetchedIconSettings.iconBottomPadding)
                }
            }
            fetchInstance()
        }
    }, [iconForm, fetchInstanceById, instance])

    const onClickReturnBack = () => {
        navigate(-1)
    }

    if (isIdLoading) {
        return (
            <div className='flex h-96 w-screen items-center justify-center'>
                <LoadingIcon loading={isIdLoading} className='mr-2'/>
            </div>
        )
    }

    return (
        <div className='flex flex-col justify-center gap-3 '>
            {
                /* <div className="grid grid-cols-3 justify-center border-b pb-2">
                <Button variant="outline" className="flex items-center gap-2 justify-self-center" onClick={onClickReturnBack}>
                    <ChevronLeft />
                    Back
                </Button>
                <h2 className="justify-self-center text-3xl font-semibold">{instance?.name}</h2>
            </div> */
            }
            <div className='flex w-full justify-center gap-3 px-4'>
                <Card className='w-2/5 p-3'>
                    <Form {...iconForm}>
                        <form onSubmit={iconForm.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
                            <FormField
                                control={iconForm.control}
                                name='iconRadius'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Icon style</FormLabel>
                                        <FormControl>
                                            <RadioGroup value={field.value} onValueChange={field.onChange}>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='square' id='r1'/>
                                                    <Label htmlFor='r1'>Square</Label>
                                                </div>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='circle' id='r2'/>
                                                    <Label htmlFor='r2'>Circle</Label>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>

                            <FormField
                                control={iconForm.control}
                                name='iconBgColorStyle'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Icon background style</FormLabel>
                                        <FormControl>
                                            <RadioGroup value={field.value} onValueChange={field.onChange}>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='mono' id='r1'/>
                                                    <Label htmlFor='r1'>Mono color</Label>
                                                </div>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='gradient' id='r2'/>
                                                    <Label htmlFor='r2'>Gradient</Label>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={iconForm.control}
                                name='iconBg'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                type='color'
                                                {...field}
                                                onInput={e => {}}
                                            />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            {iconForm.watch('iconBgColorStyle') === 'gradient' && (
                                <FormField
                                    control={iconForm.control}
                                    name='iconBgSecond'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input type='color' {...field} onInput={e => {}}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            )}

                            <Separator/>
                            <FormField
                                control={iconForm.control}
                                name='iconSize'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Icon size - {field.value}</FormLabel>
                                        <FormControl>
                                            <Input type='range' min='35' max='150' step='1' {...field} onInput={e => {}}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>
                            <FormField
                                control={iconForm.control}
                                name='iconPosition'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Icon style</FormLabel>
                                        <FormControl>
                                            <RadioGroup value={field.value} onValueChange={field.onChange}>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='left' id='r1'/>
                                                    <Label htmlFor='r1'>Left bottom</Label>
                                                </div>
                                                <div className='flex items-center space-x-2'>
                                                    <RadioGroupItem value='right' id='r2'/>
                                                    <Label htmlFor='r2'>Right bottom</Label>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={iconForm.control}
                                name='iconPositionPadding'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>
                                            Padding {iconForm.watch('iconPosition')} - {field.value}
                                        </FormLabel>
                                        <FormControl>
                                            <Input className='px-0' type='range' min='10' max='150' step='1' {...field} onInput={e => {}}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>
                            <FormField
                                control={iconForm.control}
                                name='iconBottomPadding'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Padding bottom - {field.value}</FormLabel>
                                        <FormControl>
                                            <Input className='px-0' type='range' min='10' max='150' step='1' {...field} onInput={e => {}}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <Button type='submit' className='mt-5'>
                                Update icon
                            </Button>
                        </form>
                    </Form>
                </Card>
                <div className='relative h-fit w-2/5 rounded-sm border bg-slate-50'>
                    <div className='h-10 rounded-sm bg-gray-200'></div>
                    <div className='p-4 '>
                        <div className='flex flex-col gap-3'>
                            <div className='h-4 w-3/4 rounded-sm bg-gray-300'></div>
                            <div className='h-4 w-3/4 rounded-sm bg-gray-300'></div>
                            <div className='h-4 w-3/4 rounded-sm bg-gray-300'></div>
                            <div className='h-4 w-3/4 rounded-sm bg-gray-300'></div>
                            <div className='h-4 w-3/4 rounded-sm bg-gray-300'></div>
                        </div>
                    </div>
                    <div
                        className={`absolute z-50 cursor-pointer`}
                        style={{
                            color: 'white',

                            borderRadius: '50%',
                            width: 'fit-content',
                            height: 'fit-content',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            left: `${iconForm.watch('iconPosition') === 'left' ? iconForm.watch('iconPositionPadding') + 'px' : 'auto'}`,
                            right: `${iconForm.watch('iconPosition') === 'right' ? iconForm.watch('iconPositionPadding') + 'px' : 'auto'}`,
                            bottom: iconForm.watch('iconBottomPadding') + 'px',
                        }}
                    >
                        <svg
                            width={`${iconForm.watch('iconSize')}`}
                            height={`${iconForm.watch('iconSize')}`}
                            viewBox='0 0 62 62'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <g filter='url(#filter0_d_0_1)'>
                                <rect
                                    x='7'
                                    y='7'
                                    width='48'
                                    height='48'
                                    rx={iconForm.watch('iconRadius') === 'circle' ? '100' : '10'}
                                    fill={iconForm.watch('iconBgColorStyle') === 'gradient'
                                        ? 'url(#filter0_d_0_1)'
                                        : iconForm.watch('iconBg')}
                                />
                            </g>
                            <path
                                d='M31 46C22.7158 46 16 39.2842 16 31C16 22.7157 22.7158 16 31 16C39.2843 16 46 22.7157 46 31C46 33.3995 45.4366 35.6674 44.4348 37.6787C44.1686 38.2132 44.08 38.8241 44.2343 39.4009L45.1277 42.7401C45.5156 44.1895 44.1895 45.5155 42.74 45.1278L39.4009 44.2344C38.8241 44.08 38.2132 44.1686 37.6787 44.4347C35.6674 45.4366 33.3995 46 31 46Z'
                                fill='white'
                            />
                            <defs>
                                <linearGradient
                                    id='filter0_d_0_1'
                                    x1='10.4118'
                                    y1='-7.26741e-07'
                                    x2='42.7059'
                                    y2='48'
                                    gradientUnits='userSpaceOnUse'
                                >
                                    <stop stop-color={iconForm.watch('iconBg')}/>
                                    <stop offset='1' stop-color={iconForm.watch('iconBgSecond')}/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
})
