import { Button } from 'components/ui/button'
import { slotItemParentClassName } from 'config/constants'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'
import { useGetInstanceChats } from '../hooks/use-instance-chats'
import { useInstanceStore } from '../store/instances.store'
import { InstanceType } from '../types/instance.type'

interface InstanceDeleteSlotItemProps extends React.HTMLAttributes<HTMLButtonElement> {
    instanceId: InstanceType['id'] | undefined
    instances?: InstanceType[]
}
export const InstanceDeleteSlotItem: React.FC<InstanceDeleteSlotItemProps> = memo(
    ({ instanceId, className, instances, children, ...props }) => {
        const deleteInstance = useInstanceStore(state => state.deleteInstance)
        const { data, loading } = useGetInstanceChats(instanceId)

        const navigate = useNavigate()

        const onDelete = useCallback(async () => {
            try {
                if (!instanceId) {
                    throw new Error('No chat id')
                }
                if (!loading) {
                    if (data?.length && data.length > 0) {
                        toastConfig.instance.delete.warn()
                        return
                    }

                    await deleteInstance(instanceId)
                    navigate(urlConfig.pages.main)
                    toastConfig.instance.delete.success()
                }
            } catch (error) {
                console.error(error)
                toastConfig.unknownError()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data?.length, deleteInstance, instanceId, loading])

        return (
            <Button
                onClick={onDelete}
                disabled={loading}
                {...props}
                variant='ghost'
                className={cn(
                    slotItemParentClassName,
                    'text-destructive hover:text-destructive ',
                    'h-9 w-full px-2 py-0 pr-4 text-left font-normal',
                    className,
                )}
            >
                Delete
            </Button>
        )
    },
)
InstanceDeleteSlotItem.displayName = InstanceDeleteSlotItem.name
