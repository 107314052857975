import { zodResolver } from '@hookform/resolvers/zod'
import { SlotItemWithIconReversed } from 'components/slot-item-with-icon'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { DropdownMenuItem } from 'components/ui/dropdown-menu'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toast.config'
import { Pencil } from 'lucide-react'
import { memo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useInstanceStore } from '../store/instances.store'
import { InstanceType } from '../types/instance.type'

interface InstanceUpdateSlotItemProps extends React.HTMLAttributes<HTMLButtonElement> {
    instance: InstanceType
}

const updateInstanceSchema = z.object({
    name: z.string().max(255),
})

export const InstanceUpdateSlotItem: React.FC<InstanceUpdateSlotItemProps> = memo(
    ({ instance, className, children, ...props }) => {
        const updateInstance = useInstanceStore(state => state.updateInstance)

        const updateForm = useForm<z.infer<typeof updateInstanceSchema>>({
            defaultValues: {
                name: instance.name,
            },
            resolver: zodResolver(updateInstanceSchema),
        })

        async function onSubmit(values: z.infer<typeof updateInstanceSchema>) {
            try {
                await updateInstance({ ...instance, name: values.name })
                toastConfig.instance.update.success()
            } catch (error) {
                console.error(error)
                toastConfig.instance.update.error()
            }
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    <Button variant='ghost' className='h-9 w-full px-2 py-0 pr-4 font-normal text-left'>
                        Update
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>You can update project name</AlertDialogTitle>
                    </AlertDialogHeader>
                    <Form {...updateForm}>
                        <form onSubmit={updateForm.handleSubmit(onSubmit)} className='space-y-8'>
                            <FormField
                                control={updateForm.control}
                                name='name'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder='VistaChat' {...field}/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction type='submit'>Update</AlertDialogAction>
                            </AlertDialogFooter>
                        </form>
                    </Form>
                </AlertDialogContent>
            </AlertDialog>
        )
    },
)
InstanceUpdateSlotItem.displayName = InstanceUpdateSlotItem.name
