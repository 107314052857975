import { LoadingButton } from 'components/loading-button'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from 'components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { ChatFullscreenImagePreview } from 'modules/chat/components/chat-fullscreen-image-preview-modal'
import { useChatModalStore } from 'modules/chat/store/chat-modal.store'
import { FileFormSchemaType } from 'modules/chat/types/file-form.schema'
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { cn } from 'utils/cn'

interface ChatSendImageModalProps extends Omit<React.ComponentProps<typeof DialogTrigger>, 'form' | 'onSubmit'> {
    form: UseFormReturn<FileFormSchemaType>
    onSubmit: SubmitHandler<FileFormSchemaType>
    textColor?: string
}
export const ChatSendImageModal = memo(
    forwardRef<React.ComponentRef<typeof DialogTrigger>, React.PropsWithRef<ChatSendImageModalProps>>(
        ({ form, onSubmit, textColor, ...props }, ref) => {
            const path = window.location.pathname

            const open = useChatModalStore(state => state.sendImageModalOpen)
            const setOpen = useChatModalStore(state => state.setSendImageModalOpen)

            const [loading, setLoading] = useState<boolean>(false)
            const [imageSrc, setImageSrc] = useState<string>('')

            const submitButtonRef = useRef<React.ComponentRef<typeof LoadingButton> | null>(null)

            const onClose = useCallback(() => {
                setImageSrc('')
                form.reset()
                setOpen(false)
            }, [form, setOpen])

            const handleSubmit = useCallback(
                async (values: FileFormSchemaType) => {
                    setLoading(true)
                    await onSubmit(values)
                    setLoading(false)
                    onClose()
                },
                [onClose, onSubmit],
            )

            form.watch()

            // update image preview on file change
            useEffect(() => {
                const subscription = form.watch(value => {
                    if (!value.file) {
                        setImageSrc('')
                        return
                    }
                    const src = URL.createObjectURL(value.file)
                    setImageSrc(src)
                })
                return () => {
                    subscription.unsubscribe()
                    setImageSrc('')
                }
            }, [form, form.watch])

            // clear form on close
            useEffect(() => {
                if (!open) {
                    setTimeout(() => {
                        setImageSrc('')
                        form.reset()
                    }, 256)
                }
            }, [form, open])

            // focus submit button on open
            useEffect(() => {
                if (open) {
                    setTimeout(() => {
                        submitButtonRef.current?.focus()
                    }, 50)
                }
            }, [open])

            return (
                <Dialog open={open} onOpenChange={setOpen}>
                    <DialogTrigger ref={ref} {...props}/>
                    <DialogContent
                        className={cn(
                            'border-none selection:bg-slate-600 selection:text-gray-300',
                            path === '/client' && 'bg-chatcontent',
                            textColor,
                        )}
                    >
                        <DialogHeader>
                            <DialogTitle>Send image</DialogTitle>
                            <DialogDescription className={cn('text-sm', textColor)}>
                                Select or paste a photo to send
                            </DialogDescription>
                        </DialogHeader>
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-5'>
                                <FormField
                                    name='file'
                                    control={form.control}
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Image</FormLabel>
                                            <FormControl>
                                                <Input
                                                    type='file'
                                                    accept='image/*'
                                                    name={field.name}
                                                    ref={field.ref}
                                                    onBlur={field.onBlur}
                                                    onChange={e => field.onChange(e.target.files?.[0])}
                                                    className={cn(
                                                        textColor === 'text-black' ? 'border-black' : 'border-white',
                                                        textColor === 'text-black'
                                                            ? 'placeholder:text-black'
                                                            : 'placeholder:text-white',
                                                    )}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                                {imageSrc.length !== 0 && (
                                    <>
                                        <div className='relative'>
                                            <div className='absolute inset-0 flex items-center'>
                                                <span className='w-full border-t'/>
                                            </div>
                                            <div className='relative flex justify-center text-xs uppercase'>
                                                <span className='bg-card text-muted-foreground px-2'>Preview</span>
                                            </div>
                                        </div>
                                        <ChatFullscreenImagePreview
                                            src={imageSrc}
                                            className='flex w-full items-center justify-center'
                                        >
                                            <img src={imageSrc} className='max-h-96 w-fit rounded-md border object-contain'/>
                                        </ChatFullscreenImagePreview>
                                    </>
                                )}
                                <div className='flex w-full items-center justify-end gap-3'>
                                    <Button variant='ghost' onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <LoadingButton ref={submitButtonRef} type='submit' loading={loading}>
                                        Send image
                                    </LoadingButton>
                                </div>
                            </form>
                        </Form>
                    </DialogContent>
                </Dialog>
            )
        },
    ),
)
ChatSendImageModal.displayName = ChatSendImageModal.name
