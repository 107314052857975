/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { getCurrentISODate } from 'config/dayjs.config'
import { dbUsers } from 'config/firebase.config'
import { toastConfig } from 'config/toast.config'
import { urlConfig } from 'config/url.config'
import { where } from 'firebase/firestore'
import { useThemeStore } from 'modules/theme/store/theme.store'
import { useLogin } from 'modules/user/hooks/use-login'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import { getUserIp } from 'modules/user/utils/get-user-ip'
import { getUserLocation } from 'modules/user/utils/get-user-location'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'
import { z, ZodError } from 'zod'

const passwordTypes = {
    password: 'password',
    text: 'text',
}

const registerSchema = z.object({
    name: z.string().min(3, {
        message: 'Name must be at least 3 characters long',
    }),
    email: z
        .string()
        .email({
            message: 'Wrong email address',
        })
        .min(5),
    password: z
        .string()
        .min(8, { message: '8 characters long' })
        .regex(/[a-z]/, { message: 'lowercase' })
        .regex(/[A-Z]/, { message: 'uppercase' })
        .regex(/\d/, { message: 'number' })
        .regex(/[^a-zA-Z0-9]/, {
            message: 'special',
        }),
})

const Register = () => {
    const theme = useThemeStore(state => state.theme)
    const { isButtonDisabled, registerWithEmailAndPassword, login } = useLogin()

    const [passwordType, setPasswordType] = useState<string>(passwordTypes.password)

    const registerForm = useForm<z.infer<typeof registerSchema>>({
        resolver: zodResolver(registerSchema),
        defaultValues: {
            email: '',
            password: '',
            name: '',
        },
    })

    const { handleSubmit, control, watch, setValue } = registerForm
    const password = watch('password')
    const [passwordErrors, setPasswordErrors] = useState<string[]>([])

    useEffect(() => {
        const fetchPasswordErrors = async () => {
            try {
                await registerSchema.parseAsync({ password })
                setPasswordErrors(prev => [])
            } catch (error: any) {
                if (error instanceof ZodError) {
                    setPasswordErrors(prev => error.errors.map((message: { message: any }) => message.message))
                }
            }
        }
        fetchPasswordErrors()
    }, [password, setValue])

    async function onSubmit(values: z.infer<typeof registerSchema>) {
        const ip = await getUserIp()
        const userLocation = await getUserLocation(ip)

        const cb = async () => {
            await dbUsers.create({
                name: values.name,
                email: values.email,
                role: UserRole.ADMIN,
                userOwnerId: 'owner',
                registered_ip: ip,
                registered_date: getCurrentISODate(),
                registered_location: JSON.stringify(userLocation),
            })

            await login({
                authState: {
                    name: values.name,
                    email: values.email,
                },
                callbackUrl: urlConfig.pages.main,
            }).then(() => {
                toastConfig.register.success(values.name)
                registerForm.reset()
            })
        }

        registerWithEmailAndPassword(values.email, values.password, values.name, cb)
    }

    return (
        <div className=' flex w-screen flex-col items-center justify-center'>
            <div className={cn('flex flex-col gap-3 rounded-xl p-5 lg:w-1/3', theme === 'dark' ? ' bg-zinc-800' : 'bg-white')}>
                <span className='font-serif text-4xl'>Get started</span>
                <span className='text-lg'>No credit card required</span>
                <Form {...registerForm}>
                    <form onSubmit={handleSubmit(onSubmit)} className='flex w-full flex-col gap-5'>
                        <FormField
                            control={registerForm.control}
                            name='name'
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className='text-lg'>Name</FormLabel>
                                    <FormControl>
                                        <Input className='border-zinc-900' type='text' placeholder='John Doe' {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={registerForm.control}
                            name='email'
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className='text-lg'>Email</FormLabel>
                                    <FormControl>
                                        <Input className='border-zinc-900' type='email' placeholder='email@doe.com' {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name='password'
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className='flex justify-between text-lg'>
                                        <span>Password</span>
                                        <span
                                            className='cursor-pointer text-blue-600'
                                            onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                                        >
                                            Show
                                        </span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            id='password'
                                            className='border-zinc-900'
                                            placeholder='*****'
                                            type={passwordType}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        {password.length > 0 && (
                            <ul className='ul_pass flex flex-wrap gap-2'>
                                <li className={!passwordErrors.find(error => error === 'lowercase') ? 'li_done' : 'li_no'}>
                                    <div className='text_sm_dop'>Lowercase</div>
                                </li>
                                <li className={!passwordErrors.find(error => error === 'uppercase') ? 'li_done' : 'li_no'}>
                                    <div className='text_sm_dop'>Uppercase</div>
                                </li>
                                <li className={!passwordErrors.find(error => error === 'number') ? 'li_done' : 'li_no'}>
                                    <div className='text_sm_dop'>Number</div>
                                </li>
                                <li className={!passwordErrors.find(error => error === 'special') ? 'li_done' : 'li_no'}>
                                    <div className='text_sm_dop'>Special character</div>
                                </li>
                                <li
                                    className={!passwordErrors.find(error => error === '8 characters long') ? 'li_done' : 'li_no'}
                                >
                                    <div className='text_sm_dop'>8 characters minimum</div>
                                </li>
                            </ul>
                        )}
                        <p className='text-center text-base'>
                            By creating an account, you agree to our <span className='cursor-pointer text-blue-600'>Terms</span>{' '}
                            and have read and acknowledge the <span className='cursor-pointer text-blue-600'>Privacy Statement</span>.
                        </p>
                        <Button disabled={isButtonDisabled} type='submit' className='rounded-full py-7 text-lg uppercase'>
                            <LoadingIcon loading={isButtonDisabled} className='mr-2'/>
                            Sign up
                        </Button>
                        <div className='flex justify-center gap-1 text-xl'>
                            <span>Have an account?</span>
                            <Link to='/login' className='cursor-pointer text-blue-600'>
                                Log in
                            </Link>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}
export default Register
