import { Button } from 'components/ui/button'
import { pageTransitionClassName } from 'config/constants'
import { urlConfig } from 'config/url.config.ts'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface NotFoundPageProps {}
export const NotFoundPage: React.FC<NotFoundPageProps> = memo(() => {
    return (
        <div
            className={cn('flex h-full min-h-screen w-full flex-col items-center justify-center gap-8', pageTransitionClassName)}
        >
            <h1 className='text-5xl'>404 | Page not found</h1>
            <Button asChild>
                <Link to={urlConfig.pages.main}>Go Home</Link>
            </Button>
        </div>
    )
})
NotFoundPage.displayName = NotFoundPage.name
