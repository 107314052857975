import { Table } from '@tanstack/react-table'
import { Button } from 'components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react'

const rowsPerPage = [5, 10, 20, 30, 40, 50, 100]

interface DataTablePaginationProps<TData> {
    table: Table<TData>
}
export const DataTablePagination = <TData,>({ table }: DataTablePaginationProps<TData>) => {
    const currentValue = table.getState().pagination.pageSize
    const currentPage = table.getState().pagination.pageIndex + 1
    const pageCount = table.getPageCount()
    const canGetPreviousPage = table.getCanPreviousPage()
    const canGetNextPage = table.getCanNextPage()

    const onValueChange = (value: string) => {
        table.setPageSize(Number(value))
    }
    const onFirstPageClick = () => {
        table.setPageIndex(0)
    }
    const onPreviousPageClick = () => {
        table.previousPage()
    }
    const onNextPageClick = () => {
        table.nextPage()
    }
    const onLastPageClick = () => {
        table.setPageIndex(pageCount - 1)
    }

    return (
        <div className='mt-2 flex items-center justify-center px-2'>
            <div className='flex items-center space-x-6 lg:space-x-8'>
                <div className='flex items-center space-x-2'>
                    <p className='text-sm font-medium'>Rows per page</p>
                    <Select value={String(currentValue)} onValueChange={onValueChange}>
                        <SelectTrigger className='h-8 w-[70px]'>
                            <SelectValue placeholder={currentValue}/>
                        </SelectTrigger>
                        <SelectContent side='top'>
                            {rowsPerPage.map(pageSize => (
                                <SelectItem key={pageSize} value={String(pageSize)}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className='flex w-[100px] items-center justify-center text-sm font-medium'>
                    Page {currentPage} of {pageCount}
                </div>
                <div className='flex items-center space-x-2'>
                    <Button
                        variant='outline'
                        className='hidden h-8 w-8 p-0 lg:flex'
                        onClick={onFirstPageClick}
                        disabled={!canGetPreviousPage}
                    >
                        <span className='sr-only'>Go to first page</span>
                        <ChevronsLeft className='h-4 w-4'/>
                    </Button>
                    <Button
                        variant='outline'
                        className='h-8 w-8 p-0'
                        onClick={onPreviousPageClick}
                        disabled={!canGetPreviousPage}
                    >
                        <span className='sr-only'>Go to previous page</span>
                        <ChevronLeft className='h-4 w-4'/>
                    </Button>
                    <Button variant='outline' className='h-8 w-8 p-0' onClick={onNextPageClick} disabled={!canGetNextPage}>
                        <span className='sr-only'>Go to next page</span>
                        <ChevronRight className='h-4 w-4'/>
                    </Button>
                    <Button
                        variant='outline'
                        className='hidden h-8 w-8 p-0 lg:flex'
                        onClick={onLastPageClick}
                        disabled={!canGetNextPage}
                    >
                        <span className='sr-only'>Go to last page</span>
                        <ChevronsRight className='h-4 w-4'/>
                    </Button>
                </div>
            </div>
        </div>
    )
}
