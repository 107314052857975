import { dotenv, ENV } from 'config/dotenv.config'

export const urlConfig = {
    API_URL: dotenv.get(ENV.API_URL),
    pages: {
        'admin': '/admin',
        'all-login': '/all-login',
        'chatId': '/chat/:id',
        'client': '/client',
        'customize': 'customize-colors',
        'icon': 'customize-icon',
        'instance': '/instance/:id',
        'login': '/login',
        'main': '/',
        'notFound': '/404',
        'profile': '/profile',
        'register': '/register',
        'reset': '/reset',
        'statistics': '/statistics',
    },
    api: {
        adminUsers: '/user/admin',
        chats: '/chats',
        clientScript: '/chats/chatScript/:instanceId',
        gpt: '/gpt',
        instanceChats: '/chats/instance',
        login: '/auth/login',
        newUserReg: '/email/registration',
        profile: '/user/profile',
        sites: '/sites',
        user: '/user',
        userCoordinates: '/user/coordinates',
        userStat: '/chats/statistics',
    },
}
