import { CodeSnippet } from 'components/code-snippet'

interface InstanceCodeSnippetProps {
    scriptUrl: string
}

const InstanceCodeSnippet: React.FC<InstanceCodeSnippetProps> = ({ scriptUrl, ...props }) => {
    return (
        <div>
            <CodeSnippet
                language='html'
                label='This is a code snippet that need to be implemented in website. Copy this code snippet, and paste it in the end your html body'
            >
                {`<script src="${scriptUrl}" async></script>`}
            </CodeSnippet>
        </div>
    )
}
export default InstanceCodeSnippet
