import { urlConfig } from 'config/url.config'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { UserRole } from 'modules/user/types/schemas/user.schema'
import { memo, PropsWithChildren } from 'react'
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface RequireAuthProps {
    callbackPath?: string
    allowedRoles?: UserRole[]
}
export const RequireAuth: React.FC<PropsWithChildren<RequireAuthProps>> = memo(
    ({ callbackPath = urlConfig.pages.login, allowedRoles = [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.USER], children }) => {
        const isAuth = useIsAuthenticated()()
        const auth = useAuthUser()()

        if (!isAuth) {
            return <Navigate to={callbackPath} replace/>
        }
        if (!allowedRoles.includes(auth?.role)) {
            return <Navigate to={callbackPath} replace/>
        }

        return children
    },
)
RequireAuth.displayName = RequireAuth.name
