import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Separator } from 'components/ui/separator'
import { toastConfig } from 'config/toast.config'
import { X } from 'lucide-react'
import { useInstanceStore } from 'modules/instances/store/instances.store'
import { InstanceType } from 'modules/instances/types/instance.type'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { clientTextColor } from 'utils/functions'
import { z } from 'zod'
import { useTextForm } from '../hooks/use-text-form'
import { ChatType } from '../types/chat.type'
import { MessageRole } from '../types/message.type'
import { useFileForm } from '../types/use-file-form'
import { hexToHSL } from '../utils/hex-to-hsl'
import { ChatContent } from './chat-content'
import { ChatFooter } from './chat-footer'
import { ChatHeader } from './chat-header'

const customizeChatExample = {
    id: '1',
    createdAt: '2022-01-01T00:00:00.000Z',
    //
    isUnread: false,
    isArchived: false,
    isBanned: false,
    //
    banInfo: null,
    location: {
        lat: 0,
        lng: 0,
        city: 'New York',
        country: 'USA',
    },
    ip: '127.0.0.1',
    username: 'John Doe',
    instanceId: '1',
    messages: [
        {
            createdAt: '2022-01-01T00:00:00.000Z',
            //
            type: 'TEXT',
            senderRole: 'CLIENT',
            content: 'Hello, world!',
        },

        {
            createdAt: '2022-01-01T00:02:00.000Z',
            //
            type: 'TEXT',
            senderRole: 'ADMIN',
            content: 'Hello. How are you?',
        },
    ],
} as ChatType

const colorsSchema = z.object({
    contentBg: z.string(),
    headerCardBg: z.string(),
    footerCardBg: z.string(),
    clientMesBg: z.string(),
})

interface CustomizeClientChatLayoutProps {
    instance: InstanceType
    step: string
    refetchInstance: () => void
}

const CustomizeClientChatLayout: React.FC<CustomizeClientChatLayoutProps> = ({ instance, step, refetchInstance, ...props }) => {
    const path = window.location.pathname

    const root = useMemo(() => document.getElementById('root'), [])

    const [textHeaderColor, setTextHeaderColor] = useState<string>('')
    const [textMessageColor, setTextMessageColor] = useState<string>('')
    const [textFooterColor, setTextFooterColor] = useState<string>('')

    const navigate = useNavigate()

    const updateInstance = useInstanceStore(state => state.updateInstance)

    const textForm = useTextForm()
    const fileForm = useFileForm()
    const onSendMessage = () => {}
    const onSendFile = () => {}

    const colorsForm = useForm<z.infer<typeof colorsSchema>>({
        resolver: zodResolver(colorsSchema),
        defaultValues: {
            contentBg: '#cccccc',
            headerCardBg: '#ffffff',
            footerCardBg: '#ffffff',
            clientMesBg: '#308cff',
        },
    })

    async function onSubmit(values: z.infer<typeof colorsSchema>) {
        try {
            await updateInstance({
                ...(instance as InstanceType),
                colorSettings: JSON.stringify(values),
            })
            refetchInstance?.()
            toastConfig.instance.updateColors.success()
        } catch (error) {
            toastConfig.instance.updateColors.error()
        }
    }

    useEffect(() => {
        if (step === 'styling') {
            if (instance) {
                const fetchInstance = async () => {
                    const fetchedColors = instance?.colorSettings && JSON.parse(instance?.colorSettings)
                    if (fetchedColors) {
                        colorsForm.setValue('contentBg', fetchedColors.contentBg ? fetchedColors.contentBg : '#ffffff')
                        colorsForm.setValue('headerCardBg', fetchedColors.headerCardBg ? fetchedColors.headerCardBg : '#ffffff')
                        colorsForm.setValue('footerCardBg', fetchedColors.footerCardBg ? fetchedColors.footerCardBg : '#ffffff')
                        colorsForm.setValue('clientMesBg', fetchedColors.clientMesBg ? fetchedColors.clientMesBg : '#308cff')

                        setTextHeaderColor(clientTextColor(fetchedColors.headerCardBg ?? '#ffffff'))
                        setTextMessageColor(clientTextColor(fetchedColors?.clientMesBg ?? '#308cff'))
                        setTextFooterColor(clientTextColor(fetchedColors?.footerCardBg ?? '#ffffff'))

                        if (root) {
                            root.style.setProperty('--chat-header', hexToHSL(fetchedColors.headerCardBg))
                            root.style.setProperty('--chat-footer', hexToHSL(fetchedColors.footerCardBg))
                            root.style.setProperty(
                                '--chat-content',
                                fetchedColors.contentBg ? hexToHSL(fetchedColors.contentBg) : '0, 0%, 100%, 0',
                            )
                            root.style.setProperty('--message', hexToHSL(fetchedColors.clientMesBg))
                        }
                    }
                }
                fetchInstance()
            }
        }
    }, [colorsForm, instance, instance?.colorSettings, path, root, step])

    return (
        <div className='flex flex-col justify-center gap-3 '>
            {
                /* <div className="grid grid-cols-3 justify-center border-b pb-2">
                <Button variant="outline" className="flex items-center gap-2 justify-self-center" onClick={onClickReturnBack}>
                    <ChevronLeft />
                    Back
                </Button>
                <h2 className="justify-self-center text-3xl font-semibold">{instance?.name}</h2>
            </div> */
            }
            <div className='flex w-full justify-center gap-3'>
                <Card className='p-3'>
                    <Form {...colorsForm}>
                        <form onSubmit={colorsForm.handleSubmit(onSubmit)} className='flex flex-col gap-2'>
                            <FormField
                                control={colorsForm.control}
                                name='headerCardBg'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Header background</FormLabel>
                                        <FormControl>
                                            <Input
                                                type='color'
                                                {...field}
                                                onInput={e => {
                                                    setTextHeaderColor(
                                                        clientTextColor((e.target as HTMLInputElement).value ?? '#ffffff'),
                                                    )
                                                    if (root) {
                                                        const hsl = hexToHSL((e.target as HTMLInputElement).value)
                                                        root.style.setProperty('--chat-header', hsl)
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <FormDescription>This is chat header background.</FormDescription>
                                        {
                                            /* contentBg: '#ffffff',
                headerCardBg: '#686D76',
                footerCardBg: '#686D76',
                clientMesBg: '#4B70F5', */
                                        }
                                        {field.value !== '#686D76' && (
                                            <Button
                                                variant='ghost'
                                                type='button'
                                                className='flex h-5 items-center gap-1 px-1 text-xs'
                                                onClick={() => {
                                                    colorsForm.setValue('headerCardBg', '#686D76')
                                                    if (root) {
                                                        root.style.setProperty('--chat-header', hexToHSL('#686D76'))
                                                    }
                                                }}
                                            >
                                                <X className='size-4'/>
                                                Clear header background
                                            </Button>
                                        )}

                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>
                            <FormField
                                control={colorsForm.control}
                                name='contentBg'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Content background</FormLabel>
                                        <FormControl>
                                            <Input
                                                type='color'
                                                {...field}
                                                onInput={e => {
                                                    if (root) {
                                                        const hsl = hexToHSL((e.target as HTMLInputElement).value)
                                                        root.style.setProperty('--chat-content', hsl)
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <FormDescription>This is chat content background.</FormDescription>
                                        {field.value !== '#ffffff' && (
                                            <Button
                                                type='button'
                                                variant='ghost'
                                                className='flex h-5 items-center gap-1 px-1 text-xs'
                                                onClick={() => {
                                                    field.onChange('#ffffff')
                                                    if (root) {
                                                        root.style.setProperty('--chat-content', '0, 0%, 100%, 0')
                                                    }
                                                }}
                                            >
                                                <X className='size-4'/>
                                                Clear content background
                                            </Button>
                                        )}
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>
                            <FormField
                                control={colorsForm.control}
                                name='clientMesBg'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Client message background</FormLabel>
                                        <FormControl>
                                            <Input
                                                type='color'
                                                {...field}
                                                onInput={e => {
                                                    setTextMessageColor(
                                                        clientTextColor((e.target as HTMLInputElement).value ?? '#308cff'),
                                                    )
                                                    if (root) {
                                                        const hsl = hexToHSL((e.target as HTMLInputElement).value)
                                                        root.style.setProperty('--message', hsl)
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <FormDescription>This is client message background.</FormDescription>
                                        {field.value !== '#308cff' && (
                                            <Button
                                                type='button'
                                                variant='ghost'
                                                className='flex h-5 items-center gap-1 px-1 text-xs'
                                                onClick={() => {
                                                    field.onChange('#308cff')
                                                    if (root) {
                                                        root.style.setProperty('--message', '221.2 83.2% 53.3%')
                                                    }
                                                }}
                                            >
                                                <X className='size-4'/>
                                                Clear content background
                                            </Button>
                                        )}
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Separator/>
                            <FormField
                                control={colorsForm.control}
                                name='footerCardBg'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Footer background</FormLabel>
                                        <FormControl>
                                            <Input
                                                type='color'
                                                {...field}
                                                onInput={e => {
                                                    setTextFooterColor(
                                                        clientTextColor((e.target as HTMLInputElement).value ?? '#ffffff'),
                                                    )
                                                    if (root) {
                                                        const hsl = hexToHSL((e.target as HTMLInputElement).value)
                                                        root.style.setProperty('--chat-footer', hsl)
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <FormDescription>This is chat footer background.</FormDescription>
                                        {field.value !== '#686D76' && (
                                            <Button
                                                type='button'
                                                variant='ghost'
                                                className='flex h-5 items-center gap-1 px-1 text-xs'
                                                onClick={() => {
                                                    colorsForm.setValue('footerCardBg', '#686D76')
                                                    if (root) {
                                                        root.style.setProperty('--chat-footer', hexToHSL('#686D76'))
                                                    }
                                                }}
                                            >
                                                <X className='size-4'/>
                                                Clear footer background
                                            </Button>
                                        )}
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                            <Button type='submit' className='mt-5'>
                                Update chat colors
                            </Button>
                        </form>
                    </Form>
                </Card>
                <div className='relative h-[500px] w-[400px] rounded-sm border'>
                    <ChatHeader
                        chat={customizeChatExample}
                        isClientPage={true}
                        isLoading={false}
                        className='pl-2'
                        textColor={textHeaderColor}
                    />
                    <ChatContent
                        chat={customizeChatExample}
                        role={MessageRole.CLIENT}
                        isLoading={false}
                        className={`h-[500px]`}
                        textMessageColor={textMessageColor}
                    />
                    <ChatFooter
                        aria-disabled={true}
                        textForm={textForm}
                        onTextSubmit={onSendMessage}
                        fileForm={fileForm}
                        onFileFormSubmit={onSendFile}
                        isLoading={false}
                        className='pl-2 w-full mb-0'
                        textColor={textFooterColor}
                    />
                </div>
            </div>
        </div>
    )
}
export default CustomizeClientChatLayout
