import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { forwardRef, memo } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { cn } from 'utils/cn'
import { NewUserSchemaType, UserRole } from '../types/schemas/user.schema'

interface ManageUsersCreateFormProps extends Omit<React.ComponentProps<'form'>, 'onSubmit'> {
    form: UseFormReturn<NewUserSchemaType>
    onSubmit: SubmitHandler<NewUserSchemaType>
    isButtonDisabled?: boolean
    userRole: UserRole
}
export const ManageUsersCreateForm = memo(
    forwardRef<React.ComponentRef<'form'>, ManageUsersCreateFormProps>(
        ({ form, onSubmit, isButtonDisabled, className, userRole, ...props }, ref) => {
            return (
                <Form {...form}>
                    <form ref={ref} onSubmit={form.handleSubmit(onSubmit)} {...props} className={cn('space-y-4', className)}>
                        <FormField
                            control={form.control}
                            name='name'
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder='John Doe' {...field} required/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name='email'
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder='someemail@email.com' {...field} required/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name='role'
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Role</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value} required>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue defaultValue={UserRole.USER} className='capitalize'/>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {Object.values(UserRole).map(role => {
                                                if (role === UserRole.SUPERADMIN || role === UserRole.ADMIN) {
                                                    return (
                                                        <SelectItem
                                                            disabled={userRole !== UserRole.SUPERADMIN}
                                                            key={role}
                                                            value={role}
                                                            className='capitalize'
                                                        >
                                                            {role}
                                                        </SelectItem>
                                                    )
                                                }
                                                return (
                                                    <SelectItem key={role} value={role} className='capitalize'>
                                                        {role}
                                                    </SelectItem>
                                                )
                                            })}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <Button
                            type='submit'
                            variant='default'
                            disabled={isButtonDisabled}
                            className='w-full rounded-full text-sm'
                        >
                            <LoadingIcon loading={isButtonDisabled} className='mr-2'/>
                            Add new user
                        </Button>
                    </form>
                </Form>
            )
        },
    ),
)
ManageUsersCreateForm.displayName = 'ManageUsersCreateForm'
