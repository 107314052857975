import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog'
import { forwardRef, memo } from 'react'

interface ChatFullscreenImagePreviewProps extends React.ComponentProps<typeof DialogTrigger> {
    src: React.ComponentProps<'img'>['src']
}
export const ChatFullscreenImagePreview: React.FC<ChatFullscreenImagePreviewProps> = memo(
    forwardRef<React.ComponentRef<typeof DialogTrigger>, React.PropsWithRef<ChatFullscreenImagePreviewProps>>(
        ({ src, ...props }, ref) => {
            return (
                <Dialog>
                    <DialogTrigger {...props} ref={ref}/>
                    <DialogContent
                        showClose={false}
                        className='flex w-full items-center justify-center p-0 outline-none focus:outline-none focus-visible:outline-none md:w-fit'
                    >
                        <img
                            src={src}
                            alt='media-message'
                            className='max-h-[90vh] w-fit max-w-[90vw] rounded-[inherit] border object-contain'
                        />
                    </DialogContent>
                </Dialog>
            )
        },
    ),
)
ChatFullscreenImagePreview.displayName = ChatFullscreenImagePreview.name
