import { memo } from 'react'

interface SVGProps extends React.HTMLAttributes<HTMLOrSVGElement> {}

const FlagIcon: React.FC<SVGProps> = memo(({ ...props }) => {
    return (
        <svg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <mask id='mask0_207_1532' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='0' y='0' width='56' height='56'>
                <rect width='56' height='56' fill='#D9D9D9'/>
            </mask>
            <g mask='url(#mask0_207_1532)'>
                <path
                    d='M11.6667 49V9.33333H32.6667L33.6001 14H46.6667V37.3333H30.3334L29.4001 32.6667H16.3334V49H11.6667ZM34.1834 32.6667H42.0001V18.6667H29.7501L28.8167 14H16.3334V28H33.2501L34.1834 32.6667Z'
                    fill='#CEC4A6'
                />
            </g>
        </svg>
    )
})
export default FlagIcon
