import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Separator } from 'components/ui/separator'
import { X } from 'lucide-react'
import { useGetUserStatistics } from 'modules/instances/hooks/use-user-stats'
import { StatsSchema } from 'modules/instances/types/instance.type'
import { useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'

const Statistics = () => {
    const auth = useAuthUser()()

    const { data, userStats, loading } = useGetUserStatistics(auth?.userOwnerId)

    const [search, setSearch] = useState<string>('')
    const [websites, setWebsites] = useState<StatsSchema[] | null>([])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        if (data) {
            if (search) {
                setWebsites(data?.filter(item => item.name.toLowerCase().includes(search.toLowerCase())))
            } else {
                setWebsites(data)
            }
        }
    }, [search, data])

    if (loading) {
        return (
            <div className='flex h-96 w-screen items-center justify-center'>
                <LoadingIcon loading={loading} className='mr-2'/>
            </div>
        )
    }
    return (
        <div className='container mx-auto flex flex-col gap-2'>
            <Card className='flex-1'>
                <CardHeader className='p-4'>
                    <CardTitle className='text-2xl'>{auth?.name}</CardTitle>
                </CardHeader>
                <CardContent className='flex items-center gap-2'>
                    <div className='flex flex-col items-center'>
                        <span className='text-3xl font-bold'>{userStats?.instances}</span>
                        <span className='text-center text-xs font-medium text-gray-700'>Total Websites</span>
                    </div>
                    <Separator orientation='vertical' className='mx-1 h-7'/>
                    <div className='flex flex-col items-center'>
                        <span className='text-3xl font-bold'>{userStats?.chats}</span>
                        <span className='text-center text-xs font-medium text-gray-700'>Total Chats</span>
                    </div>
                </CardContent>
                <CardFooter className='flex items-center gap-2'>
                    <Input
                        id='instanceName'
                        value={search}
                        onChange={handleSearch}
                        className='bg-muted md:w-1/4'
                        placeholder='Search for website...'
                    />
                    {search.length > 0 && (
                        <Button variant='ghost' className='flex items-center gap-2' onClick={() => setSearch('')}>
                            <X className='size-4'/>
                            Clear
                        </Button>
                    )}
                </CardFooter>
            </Card>
            <h2 className='text-3xl font-bold'>Your Websites</h2>
            <div className='grid w-full items-center justify-center gap-2 md:grid-cols-2 lg:grid-cols-3'>
                {websites?.map(instance => (
                    <Card className='w-full' key={instance.id}>
                        <CardHeader className='p-4'>
                            <CardTitle className='text-2xl'>{instance.name}</CardTitle>
                        </CardHeader>
                        <Separator className='mb-2'/>
                        <CardContent className='flex flex-col gap-2'>
                            <div className='grid grid-cols-2 items-center justify-between gap-1'>
                                <div className='flex flex-col items-center'>
                                    <span className='text-3xl font-bold'>{instance.chats}</span>
                                    <span className='text-center text-xs font-medium text-gray-700'>Total Chats</span>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <span className='text-3xl font-bold'>{instance.avgChatTime}</span>
                                    <span className='text-center text-xs font-medium text-gray-700'>Average Chat Time</span>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <span className='text-3xl font-bold'>{instance.unreadChats}</span>
                                    <span className='text-center text-xs font-medium text-gray-700'>Unread Chats</span>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <span className='text-3xl font-bold'>{instance.bannedChats}</span>
                                    <span className='text-center text-xs font-medium text-gray-700'>Banned Chats</span>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    )
}
export default Statistics
