import { Button } from 'components/ui/button'
import { CardTitle } from 'components/ui/card'
import { firebaseCollections, firebaseFirestore } from 'config/firebase.config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { ChatType, ChatTypeFieldsEnum } from 'modules/chat/types/chat.type'
import { SidebarBadge } from 'modules/sidebar/components/sidebar-badge'
import { useUserStore } from 'modules/user/store/user.store'
import { memo, useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { cn } from 'utils/cn'
import { InstanceType } from '../types/instance.type'

interface SidebarInstanceButtonProps extends React.ComponentProps<typeof Button> {
    instance: InstanceType
    instances?: InstanceType[]
    active?: boolean
}
export const SidebarInstanceButton: React.FC<SidebarInstanceButtonProps> = memo(
    ({ instance, instances, className, active, ...props }) => {
        const auth = useAuthUser()()

        const [isUnread, setIsUnread] = useState<boolean>(false)

        const notifications = useUserStore(state => state.notifications)

        useEffect(() => {
            const q = query(
                collection(firebaseFirestore, firebaseCollections.chats),
                where(ChatTypeFieldsEnum.INSTANCE_ID, '==', instance.id),
            )
            const unsub = onSnapshot(q, snapshot => {
                const chats = snapshot.docs.map(
                    doc => ({
                        ...doc.data(),
                        id: doc.id,
                    } as ChatType),
                )
                setIsUnread(chats.some(chat => chat.isUnread))
            })

            return () => unsub()
        }, [instance])

        return (
            <div className='flex w-full items-center'>
                <Button
                    key={instance.id}
                    variant='ghost'
                    asChild
                    {...props}
                    className={cn(
                        'group relative h-auto w-full cursor-pointer justify-start px-1 py-3',
                        'hover:bg-blue-50 hover:text-blue-600',
                        active && 'bg-blue-50 text-blue-600',
                        className,
                    )}
                >
                    <div className='flex items-center gap-2'>
                        <SidebarBadge
                            show={isUnread && notifications}
                            className='left-auto right-2 top-1/2 -translate-y-1/2 transform'
                        />
                        {
                            /* <ChatAvatar
                            fallback={instance.name}
                            className={cn('border transition-all duration-300 group-hover:shadow')}
                        /> */
                        }
                        <div className='flex w-full items-start justify-between'>
                            <CardTitle className='w-full max-w-[115px] overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium'>
                                {instance.name}
                            </CardTitle>
                        </div>
                    </div>
                </Button>
                {
                    /* <Popover>
                    <PopoverTrigger asChild>
                        <Button size="icon" variant="ghost">
                            <Settings />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-52">
                        {auth?.role !== UserRole.USER && (
                            <div>
                                <InstanceCustomizeSlotItem instanceId={instance.id} />
                                <InstanceCustomizeIcon instanceId={instance.id} />
                                <InstanceUpdateSlotItem instance={instance} />
                                <InstanceDeleteSlotItem instances={instances} instanceId={instance.id} />
                            </div>
                        )}
                    </PopoverContent>
                </Popover> */
                }
            </div>
        )
    },
)
SidebarInstanceButton.displayName = SidebarInstanceButton.name
