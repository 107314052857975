import { z } from 'zod'

export const profileSchema = z.object({
    username: z.string().min(2).max(50),
    email: z.string().email(),
})
export const passwordSchema = z.object({
    newPassword: z
        .string()
        .min(8, { message: '8 characters long' })
        .regex(/[a-z]/, { message: 'One lowercase' })
        .regex(/[A-Z]/, { message: 'One uppercase' })
        .regex(/\d/, { message: 'One number' })
        .regex(/[^a-zA-Z0-9]/, {
            message: 'One special',
        }),
    confirmPassword: z
        .string()
        .min(8, { message: '8 characters long' })
        .regex(/[a-z]/, { message: 'One lowercase' })
        .regex(/[A-Z]/, { message: 'One uppercase' })
        .regex(/\d/, { message: 'One number' })
        .regex(/[^a-zA-Z0-9]/, {
            message: 'One special',
        }),
})
